import React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import { Route, Switch } from "react-router-dom"

import "./styles/bootstrap.scss"
import "./styles/style.scss"

import logo from "./images/logo.png"
import logoPoznan from "./images/logo_poznan.jpg"
import ClassesPage from "./pages/ClassesPage"
import DocumentsPage from "./pages/DocumentsPage"
import GalleryPage from "./pages/GalleryPage"
import HomePage from "./pages/HomePage"

interface IAppProps extends RouteComponentProps {}

interface IAppState {
  modal: boolean
  footerHeight: number
  menu: boolean
}

class App extends React.PureComponent<IAppProps, IAppState> {
  private footer: HTMLElement | null = null
  constructor(props: IAppProps) {
    super(props)
    this.state = {
      modal: false,
      footerHeight: 0,
      menu: false,
    }
  }

  public componentDidMount() {
    if (this.footer) {
      const footerHeight: number = this.footer.getBoundingClientRect().height
      if (this.state.footerHeight !== footerHeight) {
        this.setState({
          footerHeight: footerHeight,
        })
      }
    }
    this.resize()
    var mql = window.matchMedia("(orientation: portrait)")
    mql.addListener(() => window.location.reload())

    this.scrollToPage()

    document.addEventListener("resize", () => window.location.reload())
  }

  public componentDidUpdate(prevProps: IAppProps) {
    if (this.footer) {
      const footerHeight: number = this.footer.getBoundingClientRect().height
      if (this.state.footerHeight !== footerHeight) {
        this.setState({
          footerHeight: footerHeight,
        })
      }
    }
    if (this.props.location !== prevProps.location) {
      this.scrollToPage()
    }
  }

  private scrollToPage() {
    if (this.props.location && this.props.location.pathname) {
      const page: string = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf("/") + 1)
      const element: HTMLElement | null = document.getElementById("section-" + page)
      if (element) {
        const top: number = element.getBoundingClientRect().top
        window.scrollTo({
          left: 0,
          top: window.scrollY + top - 90,
          behavior: "smooth",
        })
      } else {
        window.scrollTo(0, 0)
      }
    } else {
      window.scrollTo(0, 0)
    }
  }

  private resize() {
    const isMobile: boolean = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    const html: HTMLElement | null = document.getElementsByTagName("html").item(0)

    if (html) {
      if (isMobile) {
        html.classList.add("mobile")
      } else {
        html.classList.remove("mobile")
      }
      const mql = window.matchMedia("(orientation: portrait)")
      if (mql.matches) {
        html.classList.remove("landscape")
      } else {
        html.classList.add("landscape")
      }
    }
  }

  public render(): React.ReactNode {
    let modal: React.ReactNode = null
    if (this.state.modal) {
      modal = (
        <>
          <div
            className="modal fade show"
            id="modal"
            style={{ display: "flex", alignItems: "center", position: "fixed", zIndex: 100001 }}
            role="dialog"
          >
            <div className="modal-dialog modal-lg" role="document" style={{ width: "100%" }}>
              <div className="modal-content">
                <div className="modal-header" style={{ textAlign: "center" }}>
                  <h2
                    className="modal-title"
                    style={{
                      fontFamily: "'Ubuntu', sans-serif",
                      fontStyle: "italic",
                      marginLeft: "auto",
                      marginRight: "auto",
                      flex: 1,
                    }}
                  >
                    Ostatnie wolne miejsca. <br />
                    Zapraszamy!
                  </h2>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.setState({ modal: false })}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" style={{ zIndex: 100000 }}></div>
        </>
      )
    }
    const minHeight: number = window.innerHeight - 90 - this.state.footerHeight

    return (
      <>
        <div id="body-top" style={{ height: "90px" }}></div>
        <div
          className="navbar fixed-top navbar-expand-lg navbar-light bg-light"
          onClick={() => this.setState({ menu: false })}
        >
          <div className="container">
            <span className="navbar-brand d-flex align-items-center cursor-pointer" onClick={() => this.open("")}>
              <img src={logo} alt="Logo" width="auto" height="80px" className="d-inline-block align-top" />
              <h1 className="d-inline-block font-lobster-two font-italic margin-bottom-0 margin-left-1000">Smyki</h1>
            </span>
            <div className="navbar-facebook">
              <img src={logoPoznan} alt="Logo1" width="auto" height="35px" className="d-inline-block align-top" />
              <span
                className=" icon-facebook"
                onClick={() =>
                  window.open("https://www.facebook.com/%C5%BB%C5%82obek-Smyki-464204830432680/?fref=ts", "_blank")
                }
              ></span>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={(e) => {
                this.setState({ menu: !this.state.menu })
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {this.renderMenu()}
          </div>
        </div>
        <div id="sections" style={{ position: "relative", minHeight: minHeight + "px" }}>
          {modal}
          <Switch>
            <Route path="/gallery" component={GalleryPage} />
            <Route path="/documents" component={DocumentsPage} />
            <Route path="/classes" component={ClassesPage} />
            <Route path="/" component={HomePage} />
          </Switch>
        </div>
        {/* <div id="loader" className="loader-container">
          <div className="loader"></div>
        </div>
        */}
        <footer ref={(el) => (this.footer = el)}>
          <div className="footer-top">
            <div className="container">
              <div className="row ">
                <div className="footer-top-item start">
                  <div>
                    <span
                      className="icon-time margin-right-1000"
                      style={{ fontSize: "1.5rem", lineHeight: "1.5rem" }}
                    ></span>
                    <span>Pon - pt: 06.30 - 17.00</span>
                  </div>
                </div>
                <div className="footer-top-item">
                  <div>
                    <span
                      className="icon-phone-loc margin-right-1000"
                      style={{ fontSize: "1.5rem", lineHeight: "1.5rem" }}
                    ></span>
                    <div className="d-flex  flex-column ">
                      <span>Augusta Cieszkowskiego 70, Poznań</span>
                      <span>(+48) 511 161 626</span>
                    </div>
                  </div>
                </div>
                <div className="footer-top-item end">
                  <div>
                    <span
                      className="icon-mail margin-right-1000"
                      style={{ fontSize: "1.5rem", lineHeight: "1.5rem" }}
                    ></span>
                    <span>kontakt@zlobeksmyki.pl</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row ">
                <div className="col">
                  <span>
                    Ⓒ<strong>SMYKI. </strong>Wszystkie prawa zastrzeżone.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }

  private renderMenu(): React.ReactNode {
    let classes: string[] = ["collapse navbar-collapse justify-content-end"]
    if (this.state.menu) {
      classes.push("show")
    }
    return (
      <div className={classes.join(" ")} id="navbarNavAltMarkup" onClick={() => this.setState({ menu: false })}>
        <div className="navbar-nav align-items-center">
          <span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("home")}>
            O nas
          </span>
          <span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("dayschedule")}>
            Plan dnia
          </span>
          <span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("classes")}>
            Zajęcia
          </span>
          <span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("firstdays")}>
            Pierwsze dni
          </span>
          <span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("gallery")}>
            Galeria
          </span>
          <span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("documents")}>
            Dokumenty
          </span>
          {/*<span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("charges")}>
            Opłaty
    </span>*/}
          <span className="nav-item nav-link text-center cursor-pointer" onClick={() => this.open("contact")}>
            Kontakt
          </span>
        </div>
      </div>
    )
  }

  private open(page: string): void {
    this.props.history.push(page)
  }
}

export default withRouter(App)
