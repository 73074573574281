import React from "react"
import { isMobile } from "react-device-detect"

interface IDocumentsPageProps {}
interface IDocumentsPageState {
  height: number
}

class DocumentsPage extends React.Component<IDocumentsPageProps, IDocumentsPageState> {
  private element: HTMLElement | null = null

  constructor(props: IDocumentsPageProps) {
    super(props)
    this.state = {
      height: 300,
    }
  }

  public componentDidMount(): void {
    if (this.element?.parentElement) {
      const rect: DOMRect = this.element.parentElement.getBoundingClientRect()
      if (rect && rect.height !== this.state.height) {
        this.setState({
          height: rect.height,
        })
      }
    }
  }

  public render(): React.ReactNode {
    const pdfUrl = "https://www.zlobeksmyki.pl/Standardy Ochrony Małoletnich w Żłobku Smyki.pdf"
    return (
      <div ref={(el) => (this.element = el)} className="container">
        {isMobile ? (
          <div className="downloadLinkContainer">
            <a className="downloadLink" href={pdfUrl} download>
              Standardy Ochrony Małoletnich w Żłobku Smyki.pdf
            </a>
          </div>
        ) : (
          <iframe
            width="100%"
            height={this.state.height - 5 + "px"}
            title="Standardy Ochrony Małoletnich w Żłobku Smyki"
            src={pdfUrl}
          />
        )}
      </div>
    )
  }
}

export default DocumentsPage
